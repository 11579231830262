import React, { forwardRef, CSSProperties } from 'react';
import styled, { css } from 'styled-components';

export interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
  $active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
}

export const Action = React.memo(
  forwardRef<HTMLButtonElement, ActionProps>(
    ({ $active, className, cursor, style, ...props }, ref) => {
      return (
        <ActionButton
          ref={ref}
          {...props}
          tabIndex={0}
          style={style}
          cursor={cursor}
          $active={$active}
        />
      );
    }
  )
);

const ActionButton = styled.button<ActionProps>`
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ $active }) =>
        $active?.background ? $active?.background : css`rgba(0, 0, 0, 0.05)`};

      svg {
        fill: #6f7b88;
      }
    }
  }

  svg {
    flex: 0 0 auto;
    /* margin: auto;
    height: 100%; */
    overflow: visible;
    fill: #919eab;
  }

  &:active {
    background-color: ${({ $active }) =>
      $active?.background ? $active?.background : css`rgba(0, 0, 0, 0.05)`};

    svg {
      fill: ${({ $active }) => ($active?.fill ? $active.fill : css`#919eab;`)};
    }
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }
`;
