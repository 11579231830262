import React from 'react';
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';

import { Palette } from 'core';

type TrashProps = {
  id: UniqueIdentifier;
};

export const Trash: React.FunctionComponent<TrashProps> = React.memo(({ id }) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id,
  });

  return (
    <TrashBasket $isOver={isOver} $isActive={Boolean(active)} ref={setNodeRef}>
      DELETE
    </TrashBasket>
  );
});

const TrashBasket = styled.div<{ $isOver: boolean; $isActive: boolean }>`
  position: absolute;
  right: 254px;
  bottom: 10px;
  transform-origin: center;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 25px;
  width: 30px;
  height: 300px;
  margin: auto 0;
  border: 2.5px dashed;
  border-radius: 5px;
  transition: opacity ease 0.3s 0.2s, background-color ease 0.3s, border-color ease 0.3s;
  border-color: ${({ $isOver }) => ($isOver ? Palette.RED : Palette.WHITE)};
  background-color: rgba(0, 0, 0, ${({ $isOver }) => ($isOver ? 1 : 0.8)});
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  word-break: break-all;
  font-weight: bold;
  text-align: center;
  color: ${Palette.RED};
  font-size: 25px;
`;
