import React, { useCallback, useEffect, useRef } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { useShallow } from 'zustand/react/shallow';
import { Session } from '@supabase/supabase-js';
import { supabase } from 'supabaseClient';

import ToastProvider from 'view/components/common/Toast/ToastProvider';
import { useLocalStorage, useSessionStorage } from 'view/hooks';
import { useAuthStore, useMixerStore } from 'core/store';
import { ErrorBoundary } from 'view/components/common';
import { AppRouter } from 'core/router';

// Fix: This stays below ErrorBoundary due to circular dependency errors at runtime
import { BASE_URL, HTTPMethod, Route, SPOTIFY_URI, SpotifyUserProfile, STORAGE_KEYS } from 'core';

const TOKEN_EXPIRY_BUFFER = 60;

const isTokenValid = (expires_at: number | undefined, buffer = TOKEN_EXPIRY_BUFFER) => {
  if (!expires_at) return false;
  const now = Math.floor(Date.now() / 1000);
  return now < expires_at - buffer;
};

const App: React.FunctionComponent = () => {
  const [userSession, addUserSession, addEmailConfirmed, resetAuth] = useAuthStore(
    useShallow((state) => [
      state.session,
      state.addSession,
      state.addEmailConfirmed,
      state.resetAuth,
    ])
  );

  const [spotifyUserProfile, addSpotifyUserProfile, addSuperPlaylist, addCurrentTrackHovered] =
    useMixerStore(
      useShallow((state) => [
        state.spotifyUserProfile,
        state.addSpotifyUserProfile,
        state.addSuperPlaylist,
        state.addCurrentTrackHovered,
      ])
    );

  const [sessionAccessToken, setSessionAccessToken] = useSessionStorage(STORAGE_KEYS.SP_TOKEN, '');
  const [localRefreshToken, setLocalRefreshToken] = useLocalStorage(STORAGE_KEYS.SP_REFRESH, '');

  const spotifyUserProfileRef = useRef(spotifyUserProfile);
  const sessionAccessTokenRef = useRef(sessionAccessToken);
  const localRefreshTokenRef = useRef(localRefreshToken);
  const spExpiresAt = useRef(0);

  useEffect(() => {
    localRefreshTokenRef.current = localRefreshToken;
  }, [localRefreshToken]);

  useEffect(() => {
    sessionAccessTokenRef.current = sessionAccessToken;
  }, [sessionAccessToken]);

  useEffect(() => {
    spotifyUserProfileRef.current = spotifyUserProfile;
  }, [spotifyUserProfile]);

  useEffect(() => {
    if (userSession?.spotify_expires_at) {
      spExpiresAt.current = userSession.spotify_expires_at;
    }
  }, [userSession?.spotify_expires_at]);

  const handlePlaylistReset = useCallback(() => {
    // if (superPlaylist['A']?.length === 0) return;
    addSuperPlaylist({ A: [], B: [] });
    addCurrentTrackHovered(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = useCallback(
    async (session: Session, spotifyToken: string) => {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${spotifyToken}`,
      };

      try {
        const userProfileData = await fetch(`${SPOTIFY_URI}/me`, { headers });

        if (!userProfileData.ok)
          throw new Error(`Could not fetch user profile: ${userProfileData.statusText}`);

        const userProfile: SpotifyUserProfile = await userProfileData.json();

        const isAvatarDirty =
          session?.user?.user_metadata?.avatar_url !== userProfile.images[0]?.url;
        const isDisplayNameDirty = session?.user.user_metadata?.name !== userProfile.display_name;

        const isProfileDirty = isDisplayNameDirty || isAvatarDirty;

        if (isProfileDirty) {
          const userResponse = await fetch(`${BASE_URL}/${Route.USER}`, {
            method: HTTPMethod.PUT,
            headers: new Headers({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + session?.access_token,
            }),
            body: JSON.stringify({ spotify_token: spotifyToken }),
          });

          if (!userResponse.ok)
            throw new Error(`Could not update user profile: ${userResponse.statusText}`);

          // Refresh user session to re-syn user profile???
          // await supabase.auth.refreshSession();
        }

        addSpotifyUserProfile(userProfile);
      } catch (error) {
        console.error('Error fetching Spotify profile:', error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refreshSpotifyToken = useCallback(
    async (session: Session) => {
      const retries = 3;
      for (let attempt = 0; attempt < retries; attempt++) {
        try {
          const payload = {
            method: HTTPMethod.POST,
            signal: AbortSignal.timeout(5000), // cancel request after 5s
            headers: new Headers({
              Authorization: `Bearer ${localRefreshTokenRef.current}`,
            }),
          };

          const refreshData = await fetch(`${BASE_URL}/${Route.REFRESH_SPOTIFY}`, payload);

          if (!refreshData.ok) throw new Error(`Failed to refresh: ${refreshData.statusText}`);

          const { access_token, refresh_token, expires_at } = await refreshData.json();

          addUserSession({
            ...session,
            spotify_token: access_token,
            spotify_expires_at: expires_at,
          });

          setSessionAccessToken(access_token);

          // spExpiresAt.current = expires_at;

          if (refresh_token) {
            setLocalRefreshToken(refresh_token);
          }
        } catch (error) {
          console.error('An error occurred while refreshing the Spotify token', error);
          if (attempt === retries - 1) throw error; // Re-throw after max retries
        }
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSignedOut = useCallback(() => {
    // Reset all auth/mixer state
    addUserSession(null);
    resetAuth();
    handlePlaylistReset();

    // Clear all storage
    window.sessionStorage.clear();
    window.localStorage.clear();

    // Reset all refs
    spotifyUserProfileRef.current = null;
    sessionAccessTokenRef.current = '';
    localRefreshTokenRef.current = '';
    spExpiresAt.current = 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      // We don't need to handle sign-in events yet..
      // This can fire frequently if a user has many tabs open.
      // More info here: https://supabase.com/docs/reference/javascript/auth-onauthstatechange

      // if (_event === 'SIGNED_IN') return;

      if (_event === 'SIGNED_OUT') {
        handleSignedOut();
        return;
      }

      if (session) {
        // After the user confirms their email, we need to force the user to log back in.
        // This is becuase we need to fetch the spotify access token. Must be a bug in supabase..
        if (
          session.access_token &&
          !(localRefreshTokenRef.current || session?.provider_refresh_token)
        ) {
          addEmailConfirmed(true);
          return;
        }

        const spotifyToken = sessionAccessTokenRef.current || session?.provider_token;

        if (spotifyToken && !spotifyUserProfileRef.current?.email) {
          await getUserData(session, spotifyToken);
        }

        // When user logs in, Supabase will provide us with a initial spotify refresh/acess token
        // We'll persist both tokens into storage, then refresh access when the 'TOKEN_REFRESHED' event is fired
        if (session?.provider_token && session?.provider_refresh_token) {
          addUserSession({ ...session, spotify_token: session.provider_token });
          setLocalRefreshToken(session.provider_refresh_token);
          setSessionAccessToken(session.provider_token);

          // Set the expires_at ref to the session expires_at ??
          // spExpiresAt.current = session.expires_at;
          return;
        }

        // Spotify access tokens have the same duration as supabase auth (1hr)
        // To keep everything synced, we'll refresh spotify access when supabase refreshes theirs
        // or if user has more than one session/tab opened
        if (
          (_event === 'TOKEN_REFRESHED' || !sessionAccessTokenRef.current) &&
          !session?.provider_token &&
          localRefreshTokenRef.current &&
          (!spExpiresAt.current || !isTokenValid(spExpiresAt.current))
        ) {
          await refreshSpotifyToken(session);
        }

        // If 'TOKEN_REFRESHED' event isn't fired and there is no 'proivder_token'
        // Fetch Spotify acceess token from session storage if it exists
        if (
          _event !== 'TOKEN_REFRESHED' &&
          !session?.provider_token &&
          sessionAccessTokenRef.current &&
          (!spExpiresAt.current || isTokenValid(spExpiresAt.current))
        ) {
          addUserSession({ ...session, spotify_token: sessionAccessTokenRef.current });
          return;
        }

        // addUserSession(session);
        return;
      }
    });

    return () => subscription?.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelmetProvider>
      <ToastProvider>
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
      </ToastProvider>
    </HelmetProvider>
  );
};

export default App;
