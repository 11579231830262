import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { coordinateGetter as multipleContainersCoordinateGetter } from './components/multipleContainersKeyboardCoordinates';

import { Item, Container, ContainerProps, Trash } from './components';
import { Palette, SpotifyPlaylistData, SpotifyTrackData, useMixerStore } from 'core';
import { useToast } from '../Toast';

const CRATE_LIMIT = 5;

const defaultInitializer = (index: number) => index;

export function createRange<T = number>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)]?.map((_, index) => initializer(index));
}

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const DroppableContainer = React.memo(
  ({
    children,
    $columns = 1,
    onSubmit,
    onReset,
    onSort,
    $disabled,
    selectedPlaylist,
    id,
    items,
    style,
    $handle,
    ...props
  }: ContainerProps & {
    $disabled?: boolean;
    selectedPlaylist?: SpotifyPlaylistData | undefined;
    $handle?: boolean;
    id: UniqueIdentifier;
    items: Array<SpotifyTrackData>;
    style?: React.CSSProperties;
  }) => {
    const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } =
      useSortable({
        id,
        data: {
          type: 'container',
          children: items,
        },
        animateLayoutChanges,
      });

    const handleProps = useMemo(
      () => ({
        ...attributes,
        ...listeners,
      }),
      [attributes, listeners]
    );

    const isOverContainer = useMemo(() => {
      return over
        ? (id === over.id && active?.data.current?.type !== 'container') ||
            items.some(({ id }) => id === over.id)
        : false;
    }, [over, id, active, items]);

    const containerStyle = useMemo(
      () => ({
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }),
      [style, transition, transform, isDragging]
    );

    return (
      <Container
        ref={$disabled ? undefined : setNodeRef}
        selectedPlaylist={selectedPlaylist}
        $hover={isOverContainer}
        $disabled={$disabled}
        $columns={$columns}
        crateName={id}
        onSubmit={onSubmit}
        onSort={onSort}
        onReset={onReset}
        style={containerStyle}
        handleProps={handleProps}
        {...props}
      >
        {children}
      </Container>
    );
  }
);

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type Items = Record<UniqueIdentifier, SpotifyTrackData[]>;

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  selectedPlaylist?: SpotifyPlaylistData | undefined;
  handlePlaylistDraft?: () => void;
  onSubmit?: (type: 'sync' | 'save') => void;
  onSort?: () => void;
  onReset: (crateName: string | number) => void;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  items?: Items;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
}

export const TRASH_ID = 'void';
const PLACEHOLDER_ID = 'placeholder';
//const empty: SpotifyTrackData[] = [];

export const MultipleContainers = React.memo(
  ({
    adjustScale = false,
    cancelDrop,
    columns,
    handle = false,
    containerStyle,
    selectedPlaylist,
    coordinateGetter = multipleContainersCoordinateGetter,
    getItemStyles = () => ({}),
    wrapperStyle = () => ({}),
    onSubmit = () => {},
    onReset = () => {},
    onSort = () => {},
    minimal = false,
    modifiers,
    renderItem,
    strategy = verticalListSortingStrategy,
    trashable = false,
    vertical = false,
    scrollable,
  }: Props) => {
    const [superPlaylist, addSuperPlaylist, addCurrentTrackHovered] = useMixerStore(
      useShallow((state) => [
        state.superPlaylist,
        state.addSuperPlaylist,
        state.addCurrentTrackHovered,
      ])
    );

    const lastOverId = useRef<UniqueIdentifier | null>(null);
    const recentlyMovedToNewContainer = useRef(false);
    const { showToast } = useToast();

    const [containers, setContainers] = useState(Object.keys(superPlaylist) as UniqueIdentifier[]);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [activeCrate, setActiveCrate] = useState<UniqueIdentifier | null>('B'); // Initialising active crate to 'B'
    const isSortingContainer = activeId ? containers.includes(activeId) : false;

    const [clonedItems, setClonedItems] = useState<Items | null>(null);

    const sensors = useSensors(
      useSensor(MouseSensor),
      useSensor(TouchSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter,
      })
    );

    useEffect(() => {
      requestAnimationFrame(() => {
        recentlyMovedToNewContainer.current = false;
      });
    }, [superPlaylist]);

    /**
     * Custom collision detection strategy optimized for multiple containers
     *
     * - First, find any droppable containers intersecting with the pointer.
     * - If there are none, find intersecting containers with the active draggable.
     * - If there are no intersecting containers, return the last matched intersection
     *
     */
    const collisionDetectionStrategy: CollisionDetection = useCallback(
      (args) => {
        if (activeId && activeId in superPlaylist) {
          return closestCenter({
            ...args,
            droppableContainers: args.droppableContainers.filter(
              (container) => container.id in superPlaylist
            ),
          });
        }

        // Start by finding any intersecting droppable
        const pointerIntersections = pointerWithin(args);
        const intersections =
          pointerIntersections.length > 0
            ? // If there are droppables intersecting with the pointer, return those
              pointerIntersections
            : rectIntersection(args);
        let overId = getFirstCollision(intersections, 'id');

        if (overId != null) {
          if (overId === TRASH_ID) {
            // If the intersecting droppable is the trash, return early
            // Remove this if you're not using trashable functionality in your app
            return intersections;
          }

          if (overId in superPlaylist) {
            const containerItems = superPlaylist[overId];

            // If a container is matched and it contains items (columns 'A', 'B', 'C')
            if (containerItems.length > 0) {
              // Return the closest droppable within that container
              overId = closestCenter({
                ...args,
                droppableContainers: args.droppableContainers.filter(
                  (container) =>
                    container.id !== overId && containerItems.some(({ id }) => id === container.id)
                ),
              })[0]?.id;
            }
          }

          lastOverId.current = overId;

          return [{ id: overId }];
        }

        // When a draggable item moves to a new container, the layout may shift
        // and the `overId` may become `null`. We manually set the cached `lastOverId`
        // to the id of the draggable item that was moved to the new container, otherwise
        // the previous `overId` will be returned which can cause items to incorrectly shift positions
        if (recentlyMovedToNewContainer.current) {
          lastOverId.current = activeId;
        }

        // If no droppable is matched, return the last match
        return lastOverId.current ? [{ id: lastOverId.current }] : [];
      },
      [activeId, superPlaylist]
    );

    const findContainer = useCallback(
      (uniqueId: UniqueIdentifier) => {
        if (uniqueId in superPlaylist) {
          return uniqueId;
        }

        return Object.keys(superPlaylist).find((key) =>
          superPlaylist[key].some(({ id }) => id === uniqueId)
        );
      },
      [superPlaylist]
    );

    const getIndex = useCallback(
      (uniqueId: UniqueIdentifier) => {
        const container = findContainer(uniqueId);

        if (!container) {
          return -1;
        }

        const index = superPlaylist[container].findIndex(({ id }) => id === uniqueId);

        return index;
      },
      [findContainer, superPlaylist]
    );

    const onDragCancel = useCallback(() => {
      if (clonedItems) {
        // Reset items to their original state in case items have been
        // Dragged across containers
        addSuperPlaylist(clonedItems);
      }

      setActiveId(null);
      setClonedItems(null);
    }, [clonedItems, addSuperPlaylist]);

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragStart={({ active }) => {
          setActiveId(active.id);
          setClonedItems(superPlaylist);
        }}
        onDragOver={({ active, over }) => {
          const overId = over?.id;

          if (overId == null || overId === TRASH_ID || active.id in superPlaylist) {
            return;
          }

          const overContainer = findContainer(overId);
          const activeContainer = findContainer(active.id);

          if (!overContainer || !activeContainer) {
            return;
          }

          if (activeContainer !== overContainer) {
            const activeItems = superPlaylist[activeContainer];
            const overItems = superPlaylist[overContainer];
            const overIndex = overItems.findIndex(({ id }) => id === overId);
            const activeIndex = activeItems.findIndex(({ id }) => id === active.id);

            let newIndex: number;

            if (overId in superPlaylist) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            const newPlaylist = {
              ...superPlaylist,
              [activeContainer]: superPlaylist[activeContainer].filter(
                (track) => track.id !== active.id
              ),
              [overContainer]: [
                ...superPlaylist[overContainer].slice(0, newIndex),
                superPlaylist[activeContainer][activeIndex],
                ...superPlaylist[overContainer].slice(
                  newIndex,
                  superPlaylist[overContainer].length
                ),
              ],
            };
            addSuperPlaylist(newPlaylist);
          }
        }}
        onDragEnd={({ active, over }) => {
          if (active.id in superPlaylist && over?.id) {
            setContainers((containers) => {
              const activeIndex = containers.indexOf(active.id);
              const overIndex = containers.indexOf(over.id);

              return arrayMove(containers, activeIndex, overIndex);
            });
          }

          const activeContainer = findContainer(active.id);

          if (!activeContainer) {
            setActiveId(null);
            return;
          }

          const overId = over?.id;

          if (overId == null) {
            setActiveId(null);
            return;
          }

          if (overId === TRASH_ID) {
            addSuperPlaylist({
              ...superPlaylist,
              [activeContainer]: superPlaylist[activeContainer].filter(
                (track) => track.id !== activeId
              ),
            });

            setActiveId(null);
            return;
          }

          if (overId === PLACEHOLDER_ID) {
            // Limit the number of containers to 5
            if (containers.length >= CRATE_LIMIT) return;
            const newContainerId = getNextContainerId();

            setContainers((containers) => [...containers, newContainerId]);
            addSuperPlaylist({
              ...superPlaylist,
              [activeContainer]: superPlaylist[activeContainer].filter(
                (track) => track.id !== activeId
              ),
              [newContainerId]: [
                ...superPlaylist[activeContainer].filter((id) => id.id === activeId),
              ],
            });
            setActiveCrate(newContainerId);
            setActiveId(null);

            showToast(`Crate [${newContainerId}] added`, 'success');
            return;
          }

          const overContainer = findContainer(overId);

          if (overContainer) {
            const activeIndex = superPlaylist[activeContainer].findIndex(
              ({ id }) => id === active.id
            );
            const overIndex = superPlaylist[overContainer].findIndex(({ id }) => id === overId);

            if (activeIndex !== overIndex) {
              addSuperPlaylist({
                ...superPlaylist,
                [overContainer]: arrayMove(superPlaylist[overContainer], activeIndex, overIndex),
              });
            }
          }
          setActiveId(null);
        }}
        onDragCancel={onDragCancel}
        cancelDrop={cancelDrop}
        modifiers={modifiers}
      >
        <DnDContainer $vertical={vertical}>
          <SortableContext
            items={[...containers, PLACEHOLDER_ID]}
            strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}
          >
            <CratePannel>
              <CratePaneItemLeft>
                {containers?.map((containerId) => {
                  if (containerId === 'A') return null;
                  return (
                    <CrateButton
                      key={window.crypto.randomUUID()}
                      $color={getColor(containerId) ?? Palette.WHITE}
                      onDoubleClick={() => handleRemove(containerId)}
                      onClick={() => setActiveCrate(containerId)}
                      aria-label={`Choose crate ${containerId}`}
                      $active={activeCrate === containerId}
                    >
                      Crate {containerId}
                    </CrateButton>
                  );
                })}
              </CratePaneItemLeft>
              <CratePaneItemRight>
                Mixer {onSubmit.length ? 'Pro' : 'Lite'}
                {/* {minimal ? null : (
                  <DroppableContainer
                    id={PLACEHOLDER_ID}
                    style={{
                      color: Palette.WHITE,
                      // borderColor: Palette.WHITE,
                      // border: `2px solid ${Palette.WHITE}`,
                      height: '35px',
                      minHeight: '35px',
                      margin: 0,
                    }}
                    $disabled={isSortingContainer || containers.length >= CRATE_LIMIT}
                    items={empty}
                    onClick={handleAddColumn}
                    $placeholder
                  >
                    + Drag here to add crate +
                  </DroppableContainer>
                )}
                {trashable ? <Trash id={TRASH_ID} /> : null} */}
              </CratePaneItemRight>
            </CratePannel>
            <ContainerWrapper>
              <CrateWrapper>
                {containers?.map((containerId) => {
                  if (containerId === 'A' || activeCrate !== containerId) return null;
                  return (
                    <DroppableContainer
                      key={containerId}
                      id={containerId}
                      label={minimal ? undefined : `Crate ${containerId}`}
                      style={{ ...containerStyle, height: '600px', width: '350px' }}
                      $unstyled={minimal}
                      $columns={columns}
                      $scrollable={scrollable}
                      items={superPlaylist[containerId]}
                      onRemove={() => handleRemove(containerId)}
                      onAdd={handleAddColumn}
                      onReset={onReset}
                      $handle
                    >
                      <SortableContext items={superPlaylist[containerId]} strategy={strategy}>
                        {superPlaylist[containerId]?.map((track, index) => {
                          return (
                            <SortableItem
                              key={track.id}
                              id={track.id}
                              track={track}
                              index={index}
                              $handle={handle}
                              getIndex={getIndex}
                              style={getItemStyles}
                              renderItem={renderItem}
                              containerId={containerId}
                              wrapperStyle={wrapperStyle}
                              $disabled={isSortingContainer}
                            />
                          );
                        })}
                      </SortableContext>
                    </DroppableContainer>
                  );
                })}
              </CrateWrapper>
              <DroppableContainer
                key={'A'}
                id={'A'}
                label={`Crate A - Main → ${
                  superPlaylist['A'].length ? selectedPlaylist?.name : 'empty'
                }`}
                selectedPlaylist={selectedPlaylist}
                $columns={columns}
                items={superPlaylist['A']}
                $scrollable={scrollable}
                style={{ width: '100%' }}
                onSubmit={onSubmit}
                onSort={onSort}
                onReset={onReset}
                $unstyled={minimal}
                // onRemove={() => handleRemove(containerId)}
              >
                <SortableContext items={superPlaylist['A']} strategy={strategy}>
                  {superPlaylist['A']?.map((track, index) => {
                    return (
                      <SortableItem
                        id={track.id}
                        key={track.id}
                        track={track}
                        index={index}
                        $handle={handle}
                        containerId={'A'}
                        getIndex={getIndex}
                        style={getItemStyles}
                        renderItem={renderItem}
                        wrapperStyle={wrapperStyle}
                        $disabled={isSortingContainer}
                      />
                    );
                  })}
                </SortableContext>
              </DroppableContainer>
            </ContainerWrapper>
          </SortableContext>
        </DnDContainer>
        {createPortal(
          <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
            {activeId
              ? containers.includes(activeId)
                ? renderContainerDragOverlay(activeId)
                : renderSortableItemDragOverlay(activeId)
              : null}
          </DragOverlay>,
          document.body
        )}
        {trashable ? <Trash id={TRASH_ID} /> : null}
      </DndContext>
    );

    function renderSortableItemDragOverlay(id: UniqueIdentifier) {
      const containerId = findContainer(id) as UniqueIdentifier;
      const track = superPlaylist[containerId].find((track) => track.id === id) ?? null;
      return (
        <Item
          value={track}
          $handle={handle}
          style={getItemStyles({
            containerId: containerId,
            overIndex: -1,
            index: getIndex(id),
            value: id,
            isSorting: true,
            isDragging: true,
            isDragOverlay: true,
          })}
          wrapperStyle={wrapperStyle({ index: 0 })}
          renderItem={renderItem}
          color={getColor(containerId)}
          $dragOverlay
        />
      );
    }

    function renderContainerDragOverlay(containerId: UniqueIdentifier) {
      return (
        <Container
          label={`Crate ${containerId}`}
          $columns={columns}
          $unstyled={false}
          $shadow
          style={{
            height: '65%',
          }}
        >
          {superPlaylist[containerId]?.map((track, index) => (
            <Item
              key={track.id}
              value={track}
              $handle={handle}
              style={getItemStyles({
                containerId,
                overIndex: -1,
                index: getIndex(track.id),
                value: track.name,
                isDragging: false,
                isSorting: false,
                isDragOverlay: false,
              })}
              color={getColor(containerId)}
              wrapperStyle={wrapperStyle({ index })}
              renderItem={renderItem}
            />
          ))}
        </Container>
      );
    }

    function handleRemove(containerID: UniqueIdentifier) {
      if (containers.length <= 2) return;
      if (!containerID) return;

      // Remove the container from the list of containers
      setContainers((containers) => containers.filter((id) => id !== containerID));
      addCurrentTrackHovered(null);

      // Remove container key from items object
      // And Hmmmm. This delete method works, but it may not be the best way to do it
      delete superPlaylist[containerID];

      const containerIds = Object.keys(superPlaylist);
      const lastContainerId = containerIds[containerIds.length - 1];
      if (lastContainerId) setActiveCrate(lastContainerId);
      showToast(`Crate [${containerID}] removed`, 'failure');
    }

    function handleAddColumn() {
      // Limit the number of containers to 5
      if (containers.length >= CRATE_LIMIT) return;
      // TODO: If free user, limit the number of containers to 2

      const newContainerId = getNextContainerId();

      setActiveCrate(newContainerId);
      setContainers((containers) => [...containers, newContainerId]);
      addSuperPlaylist({
        ...superPlaylist,
        [newContainerId]: [],
      });

      showToast(`Crate [${newContainerId}] added`, 'success');
    }

    function getNextContainerId() {
      const containerIds = Object.keys(superPlaylist);
      const lastContainerId = containerIds[containerIds.length - 1];

      return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
    }
  }
);

function getColor(id: UniqueIdentifier) {
  switch (String(id)[0]) {
    case 'A':
      return '#7193f1';
    case 'B':
      return '#ffda6c';
    case 'C':
      return '#00bcd4';
    case 'D':
      return '#ef769f';
    case 'E':
      return '#80ef76';
  }

  return undefined;
}

interface SortableItemProps {
  containerId: UniqueIdentifier;
  track: SpotifyTrackData;
  id: UniqueIdentifier;
  index: number;
  $handle: boolean;
  $disabled?: boolean;
  renderItem(): React.ReactElement;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
}

function SortableItem({
  $disabled,
  id,
  index,
  $handle,
  track,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  const memoizedHandleProps = useMemo(
    () => ($handle ? { ref: setActivatorNodeRef } : undefined),
    [$handle, setActivatorNodeRef]
  );

  const memoizedWrapperStyle = useMemo(() => wrapperStyle({ index }), [wrapperStyle, index]);

  const memoizedStyle = useMemo(
    () =>
      style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      }),
    [style, index, id, isDragging, isSorting, over, getIndex, overIndex, containerId]
  );

  // Memoize the transform object to ensure it remains stable
  const memoizedTransform = useMemo(() => transform, [transform]);

  return (
    <Item
      ref={$disabled ? undefined : setNodeRef}
      value={track}
      $dragging={isDragging}
      $sorting={isSorting}
      $handle={$handle}
      handleProps={memoizedHandleProps}
      index={index}
      wrapperStyle={memoizedWrapperStyle}
      style={memoizedStyle}
      color={getColor(containerId)}
      containerId={containerId}
      transition={transition}
      transform={memoizedTransform}
      $fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}

const DnDContainer = styled.div<{ $vertical?: boolean }>`
  width: 100%;
  grid-auto-flow: ${({ $vertical }) => ($vertical ? 'row' : 'column')};
`;

const CratePannel = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
`;

const CrateButton = styled.button<{ $active?: boolean; $color: string }>`
  height: fit-content;
  padding: 2px 15px;
  border-radius: 5px;
  border: 2px solid ${({ $active, $color }) => ($active ? Palette.WHITE : $color)};
  background-color: ${({ $active, $color }) => ($active ? $color : 'transparent')};
  margin-right: 10px;

  &:hover {
    border-color: ${Palette.WHITE};
  }
`;

const CrateWrapper = styled.div``;

const CratePaneItemLeft = styled.div`
  display: flex;
  align-items: end;
`;

const CratePaneItemRight = styled.div`
  display: flex;
  justify-content: center;
  font-size: 29px;
  font-weight: bold;
  min-width: 770px;
  letter-spacing: 3px;

  &:before {
    content: '/';
    margin-right: 5px;
    color: ${Palette.SPOTIFY_GREEN};
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  width: 100%;
`;
