import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { RoutePath } from 'core/router/AppRouter';

// import { ReactComponent as CC } from 'assets/icons/cc.svg';
//<CreativeCommons width='13px' height='13px' title='Create Commons icon'/>

const Footer: React.FunctionComponent = React.memo(() => {
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState<boolean>(false);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (![RoutePath.MIXER, RoutePath.LEGAL].includes(pathname as any)) return null;

  return (
    <Container $loaded={loaded}>
      <div>© {currentYear} SPMixer.com</div>
    </Container>
  );
});

const Container = styled.footer<{ $loaded: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0) 100%);
  opacity: ${({ $loaded }) => ($loaded ? 1 : 0)};
  transition: opacity 0.5s ease-in;
  height: 7vh;
  width: 100vw;
  text-align: center;
  bottom: 0;
`;

// const CreativeCommons = styled(CC)`
//     width: 13px;
//     fill: ${Palette.RED};
//     height: auto;
// `;

export default Footer;
