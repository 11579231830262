export enum STORAGE_KEYS {
  SP_TOKEN = 'sp-token',
  SP_REFRESH = 'sp-refresh',
  CURRENT_PLAYLIST = 'current-playlist',
  PLAYLIST_TRACKS = 'current-playlist-tracks',
  PLAYLIST_CHANGED = 'current-playlist-changed',
}

export type SpotifyTrack = {
  id: string;
  uri: string;
  name: string;
  popularity: number;
  explicit: boolean;
  isrc: string;
  bpm: number;
  danceability: number;
  instrumentalness: number;
  liveness: number;
  speechiness: number;
  loudness: number;
  key: number;
  energy: number;
  valence: number;
  duration: number;
  time_signature: number;
  added_at: string;
  mode: number;
  preview_url: string;
  tempo: number;
  duration_ms: number;
  artists: Array<SpotifyArtist>;
  album: SpotifyAlbum;
  external_urls: SpotifyExternalUrls;
  external_ids: SpotifyExternalIds;
  artwork: string;
  restrictions: {
    reason?: string;
  };
  isPlayable: boolean;
};

export type SpotifyTrackAudioFeatures = {
  id: string;
  uri: string;
  name: string;
  tempo: number;
  danceability: number;
  popularity: number;
  instrumentalness: number;
  liveness: number;
  speechiness: number;
  loudness: number;
  key: number;
  mode: number;
  type: string;
  energy: number;
  valence: number;
  duration: number;
  time_signature: number;
  analysis_url: string;
  track_href: string;
  duration_ms: number;
};

export type SpotifyArtist = {
  id: string;
  name: string;
  uri: string;
  external_urls: SpotifyExternalUrls;
};

export type SpotifyAlbum = {
  id: string;
  name: string;
  release_date: string;
  album_type: string;
  spotify_url: string;
  external_urls: SpotifyExternalUrls;
  images: Array<SpotifyImage>;
};

export type SpotifyExternalUrls = {
  spotify: string;
};

export type SpotifyImage = {
  height: number;
  width: number;
  url: string;
};

export type SpotifyPlaylist = {
  id: string;
  name: string;
  public: boolean;
  collaborative: boolean;
  owner: SpotifyUser;
  description: string;
  images: Array<SpotifyImage>;
  tracks: SpotifyTracks;
  external_urls: SpotifyExternalUrls;
};

export type SpotifyImages = {
  height: number;
  width: number;
  url: string;
}

export type SpotifyFollowers = {
  href: string;
  total: number;
}

export type SpotifyUser = {
  id: string;
  href: string;
  type: string;
  uri: string;
  display_name: string;
  images: Array<SpotifyImages>;
  followers: Array<SpotifyFollowers>;
  external_urls: SpotifyExternalUrls;
};

export type SpoifyUserMinimial = Omit<SpotifyUser, 'images' | 'display_name'>;

export type SpotifyTracks = {
  href: string;
  total: number;
  items: Array<SpotifyPlaylistTrack>;
};

export type SpotifyPlaylistTrack = {
  added_at: string;
  added_by: SpoifyUserMinimial;
  track: SpotifyTrack;
  primary_color: string;
  is_local: boolean;
};

export type SpotifyExternalIds = {
  isrc: string;
};

export type SpotifyUserProfile = {
  display_name: string;
  email: string;
  external_urls: {
    spotify: string;
  };
  explicit_content: {
    filter_enabled: boolean;
    filter_locked: boolean;
  };
  followers: {
    href: string | null;
    total: number;
  };
  href: string;
  id: string;
  images: {
    height: number | null;
    url: string;
    width: number | null;
  }[];
  product: string;
  type: string;
  uri: string;
};

export type SpotifyTrackAudioAnalysis = {
  bars: Bars[];
  beats: Beats[];
  track: Track;
  meta: Meta;
  sections: AnalysisSections[];
  segments: AnalysisSegments[];
  tatums: AnalysisTatums[];
};

export type Track = {
  num_samples: number;
  duration: number;
  sample_md5: string;
  offset_seconds: number;
  window_seconds: number;
  analysis_sample_rate: number;
  analysis_channels: number;
  end_of_fade_in: number;
  start_of_fade_out: number;
  loudness: number;
  tempo: number;
  tempo_confidence: number;
  time_signature: number;
  time_signature_confidence: number;
  key: number;
  key_confidence: number;
  mode: number;
  mode_confidence: number;
  codestring: string;
  code_version: number;
  echoprintstring: string;
  echoprint_version: number;
  synchstring: string;
  synch_version: number;
  rhythmstring: string;
  rhythm_version: number;
};

export type Bars = {
  start: number;
  duration: number;
  confidence: number;
};

export type Beats = {
  start: number;
  duration: number;
  confidence: number;
};

export type Meta = {
  analyzer_version: string;
  platform: string;
  detailed_status: string;
  status_code: number;
  timestamp: number;
  analysis_time: number;
  input_process: string;
};

export type AnalysisTatums = {
  start: number;
  duration: number;
  confidence: number;
};

export type AnalysisSections = {
  start: number;
  duration: number;
  confidence: number;
  loudness: number;
  tempo: number;
  tempo_confidence: number;
  key: number;
  key_confidence: number;
  mode: number;
  mode_confidence: number;
  time_signature: number;
  time_signature_confidence: number;
};

export type AnalysisSegments = {
  start: number;
  loudness: number;
  duration: number;
  confidence: number;
  loudness_start: number;
  loudness_max_time: number;
  loudness_max: number;
  pitches: number[];
  timbre: number[];
};