import React, { forwardRef, useCallback, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import styled, { css } from 'styled-components';

import {
  MediaQuery,
  Palette,
  SpotifyPlaylistData,
  useAuthStore,
  isArrayDirty,
  useMixerStore,
} from 'core';
import { useCopyToClipboard, useToast } from 'view/hooks';
import { Modal } from 'view/components/common';
import { Add, Handle, Remove } from '.';
// import magic from 'assets/icons/magic.svg';

const actionStyle: React.CSSProperties = {
  height: '30px',
  width: '30px',
  padding: 0,
  marginLeft: '5px',
};

export interface ContainerProps {
  children: React.ReactNode;
  $columns?: number;
  label?: string;
  style?: React.CSSProperties;
  $horizontal?: boolean;
  $hover?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  selectedPlaylist?: SpotifyPlaylistData | undefined;
  crateName?: string | number;
  $scrollable?: boolean;
  $shadow?: boolean;
  $placeholder?: boolean;
  $disabled?: boolean;
  $unstyled?: boolean;
  onReset?: (crateName: string | number) => void;
  onSubmit?: (type: 'sync' | 'save') => void;
  onSort?: () => void;
  onRemove?(): void;
  onClick?(): void;
  onAdd?(): void;
}

export const Container = forwardRef<HTMLDivElement | HTMLButtonElement, ContainerProps>(
  (
    {
      children,
      $columns = 1,
      handleProps,
      $horizontal,
      crateName = 'A',
      selectedPlaylist,
      onSubmit,
      onReset,
      onSort,
      $hover,
      onClick,
      onRemove,
      onAdd,
      label,
      style,
      $placeholder,
      $scrollable,
      $disabled,
      $unstyled,
      $shadow,
      ...props
    },
    ref
  ) => {
    // eslint-disable-next-line
    const [_, copy] = useCopyToClipboard();
    const { showToast } = useToast();

    const [addSelectedPlaylists, addSuperPlaylist, superPlaylist] = useMixerStore(
      useShallow((state) => [
        state.addSelectedPlaylists,
        state.addSuperPlaylist,
        state.superPlaylist,
      ])
    );

    const [user] = useAuthStore(useShallow((state) => [state.user]));

    const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);

    const handleCopy = useCallback(() => {
      if (!selectedPlaylist?.external_urls.spotify) return;
      copy(selectedPlaylist?.external_urls.spotify)
        .then(() => {
          showToast('Link copied to clipboard', 'success');
        })
        .catch(() => {
          showToast('Failed to copy link', 'failure');
        });
    }, [copy, selectedPlaylist?.external_urls.spotify, showToast]);

    const handlePlaylistTransfer = useCallback(
      (position: 'beginning' | 'end' | 'override') => {
        if (!crateName || !superPlaylist[crateName]?.length) return;

        if (position === 'beginning') {
          addSuperPlaylist({
            ...superPlaylist,
            A: [...superPlaylist[crateName], ...superPlaylist['A']],
            [crateName]: [],
          });
          showToast(`Crate ${crateName} transferred to beginning of Crate A`, 'success');
        } else if (position === 'end') {
          addSuperPlaylist({
            ...superPlaylist,
            A: [...superPlaylist['A'], ...superPlaylist[crateName]],
            [crateName]: [],
          });
          showToast(`Crate ${crateName} transferred to end of Crate A`, 'success');
        } else if (position === 'override') {
          addSuperPlaylist({ ...superPlaylist, A: superPlaylist[crateName], [crateName]: [] });
          showToast(`Crate ${crateName} overrided Crate A`, 'success');
        }

        // addSuperPlaylist({ ...superPlaylist, A: superPlaylist[crateName], [crateName]: [] });
        setOpenTransferModal(false);
      },
      [crateName, superPlaylist, addSuperPlaylist, showToast]
    );

    const buckets = { ...superPlaylist };
    if (buckets['A']) delete buckets['A'];

    const memoOnAdd = useCallback(() => onAdd && onAdd(), [onAdd]);
    const memoOnRemove = useCallback(() => onRemove && onRemove(), [onRemove]);

    if (onClick)
      return (
        <Button
          {...props}
          ref={ref as React.Ref<HTMLButtonElement>}
          style={style}
          $unstyled={$unstyled}
          $horizontal={$horizontal}
          disabled={$disabled}
          $placeholder={$placeholder}
          $scrollable={$scrollable}
          onClick={onClick}
          $shadow={$shadow}
          $hover={$hover}
          tabIndex={0}
        >
          {label ? (
            <Header>
              {label}
              <Actions>
                {onRemove ? (
                  <Remove aria-label="Remove" className="Remove" onClick={memoOnRemove} />
                ) : undefined}
                <Handle aria-label="Move or drag" {...handleProps} />
              </Actions>
            </Header>
          ) : null}
          {$placeholder ? children : <UnorderedList $columns={$columns}>{children}</UnorderedList>}
        </Button>
      );
    else
      return (
        <Wrapper
          {...props}
          ref={ref as React.Ref<HTMLTableElement>}
          style={style}
          $unstyled={$unstyled}
          $disabled={$disabled}
          $horizontal={$horizontal}
          $placeholder={$placeholder}
          $isMain={crateName === 'A'}
          $scrollable={$scrollable}
          $shadow={$shadow}
          $hover={$hover}
        >
          {label ? (
            <Header $hover={$hover}>
              <span>{label}</span>
              <Actions>
                <LinkPlaylist
                  aria-label="Link Spotify playlist"
                  onClick={() => addSelectedPlaylists(crateName)}
                >
                  Link
                </LinkPlaylist>
                <LinkPlaylist
                  disabled={superPlaylist[crateName]?.length === 0}
                  onClick={() => (onReset ? onReset(crateName) : {})}
                  aria-label="Reset playlist crate"
                >
                  Reset
                </LinkPlaylist>
                {crateName === 'A' ? (
                  <React.Fragment>
                    <LinkPlaylist
                      aria-label="Sort Spotify playlist"
                      disabled={superPlaylist[crateName]?.length === 0}
                      onClick={() => (onSort ? onSort() : {})}
                    >
                      Sort
                    </LinkPlaylist>
                    <LinkPlaylist
                      disabled={superPlaylist[crateName]?.length === 0 || !user?.id}
                      // disabled={true}
                      onClick={() => (onSubmit ? onSubmit('save') : {})}
                      aria-label="Save playlist draft to Spotify"
                    >
                      Save
                    </LinkPlaylist>
                    <LinkPlaylist
                      disabled={superPlaylist[crateName]?.length === 0 || !user?.id}
                      // disabled={true}
                      onClick={() => (onSubmit ? onSubmit('sync') : {})}
                      aria-label="Submit/sync playlist to Spotify"
                    >
                      Submit
                    </LinkPlaylist>
                    <LinkPlaylist
                      disabled={superPlaylist[crateName]?.length === 0}
                      onClick={handleCopy}
                      aria-label="Share Spotify playlist"
                    >
                      Share
                    </LinkPlaylist>
                  </React.Fragment>
                ) : (
                  <LinkPlaylist
                    disabled={
                      superPlaylist['A']?.length === 0 ||
                      superPlaylist[crateName]?.length === 0 ||
                      isArrayDirty(superPlaylist[crateName], superPlaylist['A'])
                    }
                    onClick={() => setOpenTransferModal(true)}
                    aria-label="Transfer Spotify playlist to main crate A"
                  >
                    &#8646;
                  </LinkPlaylist>
                )}
                {onRemove ? (
                  <React.Fragment>
                    <Remove
                      aria-label="Remove playlist crate"
                      className="Remove"
                      onClick={memoOnRemove}
                      style={actionStyle}
                    />
                    <Add
                      aria-label="Add playlist crate"
                      className="Add"
                      onClick={memoOnAdd}
                      style={actionStyle}
                    />
                  </React.Fragment>
                ) : null}
                {/* {crateName !== 'A' ? (
                  <Handle aria-label="Move or drag playlist crate" {...handleProps} />
                ) : null} */}
              </Actions>
            </Header>
          ) : null}
          {crateName === 'A' ? (
            <>
              <TableRow>
                <TableHeader $order>#</TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Artist</TableHeader>
                <TableHeader>BPM</TableHeader>
                <TableHeader>Key</TableHeader>
                <TableHeader>Energy</TableHeader>
                <TableHeader>Mood</TableHeader>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableHeader>Name</TableHeader>
              <TableHeader>BPM / Key</TableHeader>
            </TableRow>
          )}
          {$placeholder ? children : <UnorderedList $columns={$columns}>{children}</UnorderedList>}
          <Modal
            title={`Transfer Crate ${crateName} → Crate A`}
            show={openTransferModal}
            onClose={() => setOpenTransferModal(false)}
          >
            <div>
              <ModalText>
                Transfer <u>all</u> Crate {crateName} tracks to the <b>start</b> or <b>end</b> of
                your Crate A - Main playlist. Or <b>override</b> your entire Crate A - Main
                playlist.
              </ModalText>
              <HR />
              <LinkPlaylist
                aria-label="Transfer to beginning of playlist main crate"
                onClick={() => handlePlaylistTransfer('beginning')}
              >
                Start
              </LinkPlaylist>
              <LinkPlaylist
                aria-label="Transfer to end of playlist main crate"
                onClick={() => handlePlaylistTransfer('end')}
              >
                End
              </LinkPlaylist>
              <LinkPlaylist
                aria-label="Override playlist main crate"
                onClick={() => handlePlaylistTransfer('override')}
              >
                Override
              </LinkPlaylist>
            </div>
          </Modal>
        </Wrapper>
      );
  }
);

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 35px;
  padding-right: 75px;
`;

const TableHeader = styled.div<{ $order?: boolean }>`
  min-width: 50px;
  max-width: ${({ $order }) => ($order ? '80' : '160')}px;
  color: ${Palette.WHITE};
  padding: 5px 0px;
  text-align: center;
  background-color: inherit;
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  font-weight: bold;
  font-size: 1rem;
  border-top: 2px solid ${Palette.WHITE};
  border-bottom: 2px solid ${Palette.WHITE};

  @media ${MediaQuery.laptopL} {
    min-width: ${({ $order }) => ($order ? '50' : '80')}px;
  }
`;

const Button = styled.button<ContainerProps>`
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  appearance: none;
  outline: none;
  height: 100%;
  min-width: 350px;
  margin: 10px;
  border-radius: 5px;
  min-height: 100px;
  transition: background-color 350ms ease;
  border: 2px solid ${Palette.WHITE};
  font-size: 1em;

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }

  &:disabled {
    border-color: ${Palette.WHITE};
  }

  ${({ $scrollable }) =>
    $scrollable
      ? css`
          ul {
            overflow-y: auto;
          }
        `
      : ''};

  ${({ $placeholder }) =>
    $placeholder
      ? css`
          justify-content: center;
          align-items: center;
          /* color: rgba(0, 0, 0, 0.5); */
          background-color: transparent;
          border-style: dashed;
          /* border-color: rgba(0, 0, 0, 0.08); */
          cursor: pointer;

          &:hover {
            border-color: ${Palette.SPOTIFY_GREEN};
          }

          &:disabled {
            cursor: not-allowed;
            border-color: ${Palette.WHITE};
          }
        `
      : ''};

  ${({ $hover }) =>
    $hover
      ? css`
          border-color: ${Palette.SPOTIFY_GREEN};
        `
      : ''};

  ${({ $unstyled }) =>
    $unstyled
      ? css`
          overflow: visible;
          background-color: transparent !important;
          border: none !important;
        `
      : ''};

  ${({ $horizontal }) =>
    $horizontal
      ? css`
          width: 100%;
          ul {
            grid-auto-flow: column;
          }
        `
      : ''};

  ${({ $shadow }) =>
    $shadow
      ? css`
          box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
        `
      : ''};
`;

const Wrapper = styled.div<ContainerProps & { $isMain: boolean; $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  appearance: none;
  outline: none;
  height: 600px;
  min-width: ${({ $isMain }) => ($isMain ? 710 : 350)}px;
  margin: 10px;
  border-radius: 5px;
  min-height: 100px;
  transition: background-color 350ms ease;
  border: 2px solid ${Palette.WHITE};
  font-size: 1em;

  @media ${MediaQuery.laptopL} {
    min-width: ${({ $isMain }) => ($isMain ? 770 : 350)}px;
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
  }

  ${({ $scrollable }) =>
    $scrollable
      ? css`
          ul {
            overflow-y: auto;
          }
        `
      : ''};

  ${({ $placeholder }) =>
    $placeholder
      ? css`
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.5);
          background-color: transparent;
          border-style: dashed;
          border-color: rgba(0, 0, 0, 0.08);

          &:hover {
            border-color: rgba(0, 0, 0, 0.15);
          }
        `
      : ''};

  ${({ $hover, $disabled }) =>
    $hover && !$disabled
      ? css`
          border-color: ${Palette.SPOTIFY_GREEN};
        `
      : ''};

  ${({ $unstyled }) =>
    $unstyled
      ? css`
          overflow: visible;
          background-color: transparent !important;
          border: none !important;
        `
      : ''};

  ${({ $horizontal }) =>
    $horizontal
      ? css`
          width: 100%;
          ul {
            grid-auto-flow: column;
          }
        `
      : ''};

  ${({ $shadow }) =>
    $shadow
      ? css`
          box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
        `
      : ''};
`;

const Actions = styled.div`
  display: flex;

  > * :first-child:not(:last-child) {
    opacity: 0;

    &:focus-visible {
      opacity: 1;
    }
  }
`;

const Header = styled.div<{ $hover?: boolean }>`
  display: flex;
  padding: 5px 20px;
  padding-right: 8px;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${Palette.WHITE};
  font-weight: bold;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    width: 40%;
  }

  &:hover {
    div > * {
      opacity: 1 !important;
    }
  }

  ${({ $hover }) =>
    $hover
      ? css`
          border-color: ${Palette.SPOTIFY_GREEN};
        `
      : ''};
`;

const UnorderedList = styled.ul<{ $columns?: number }>`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  list-style: none;
  padding: 0 20px 20px;
  margin: 0;
  /* height: 100%; */
`;

const LinkPlaylist = styled.button`
  height: 30px;
  line-height: 17px;
  border: 2px solid ${Palette.WHITE};
  font-weight: bold;
  border-radius: 5px;
  background-color: transparent;
  padding: 5px 10px;
  margin-left: 10px;

  &:hover {
    border-color: ${Palette.CYAN_BLUE};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ModalText = styled.p`
  text-align: start;
`;

const HR = styled.hr`
  width: 100%;
  border: 1px solid ${Palette.WHITE};
  margin: 16px 0px;
`;
