import { Session, User } from '@supabase/supabase-js';
import { create } from 'zustand';

import { SpotifyTrackData } from 'core/declarations';
import { UniqueIdentifier } from '@dnd-kit/core';
import { Database } from '../../../types_db';

export type SpmSession =
  | (Session & {
      spotify_token?: string;
      spotify_refresh_token?: string;
      spotify_expires_at?: number;
    })
  | null;

type SubRow = Database['public']['Tables']['subscriptions']['Row'];
type ProductRow = Database['public']['Tables']['products']['Row'];
type PriceRow = Database['public']['Tables']['prices']['Row'];

type Subscription =
  | (SubRow & {
      prices:
        | (PriceRow & {
            products: ProductRow | null;
          })
        | null;
    })
  | null;

type SpotifyUserProfile = {
  display_name: string;
  email: string;
  external_urls: {
    spotify: string;
  };
  explicit_content: {
    filter_enabled: boolean;
    filter_locked: boolean;
  };
  followers: {
    href: string | null;
    total: number;
  };
  href: string;
  id: string;
  images: {
    height: number | null;
    url: string;
    width: number | null;
  }[];
  product: string;
  type: string;
  uri: string;
};

type AuthStore = {
  user: User | null;
  session: SpmSession;
  userSubscription: Subscription;
  emailConfirmed: boolean;
  addEmailConfirmed: (confirmed: boolean) => void;
  addUserSubscription: (subscription: Subscription) => void;
  addSession: (session: SpmSession) => void;
  addUser: (user: User | null) => void;
  resetAuth: () => void;
};

type MixerStore = {
  spotifyUserProfile: SpotifyUserProfile | null;
  selectedPlaylists: UniqueIdentifier | null;
  superPlaylist: Record<UniqueIdentifier, SpotifyTrackData[]>;
  currentTrack: SpotifyTrackData | null;
  currentTrackHovered: SpotifyTrackData | null;
  addSuperPlaylist: (playlist: Record<UniqueIdentifier, SpotifyTrackData[]>) => void;
  addSelectedPlaylists: (playlists: UniqueIdentifier | null) => void;
  addCurrentTrackHovered: (track: SpotifyTrackData | null) => void;
  addSpotifyUserProfile: (profile: SpotifyUserProfile) => void;
  addCurrentTrack: (track: SpotifyTrackData | null) => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  session: null,
  userSubscription: null,
  emailConfirmed: false,
  addEmailConfirmed: (confirmed) => set({ emailConfirmed: confirmed }),
  addUserSubscription: (subscription) => set({ userSubscription: subscription }),
  addSession: (session) => set({ session: session, user: session?.user }),
  resetAuth: () => set({ session: null, user: null }),
  addUser: (user) => set({ user: user }),
}));

export const useMixerStore = create<MixerStore>((set) => ({
  currentTrack: null,
  userSubscription: null,
  selectedPlaylists: null,
  spotifyUserProfile: null,
  currentTrackHovered: null,
  superPlaylist: { A: [], B: [] },
  addSpotifyUserProfile: (profile) => set({ spotifyUserProfile: profile }),
  addSelectedPlaylists: (playlists) => set({ selectedPlaylists: playlists }),
  addCurrentTrackHovered: (track) => set({ currentTrackHovered: track }),
  addSuperPlaylist: (playlist) => set({ superPlaylist: playlist }),
  addCurrentTrack: (track) => set({ currentTrack: track }),
}));
